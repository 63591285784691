export class ValidationUtils {
  static validatePhoneNumber(phoneNumber: number | string): boolean {
    const valueToTest: string =
      typeof phoneNumber === "string" ? phoneNumber : phoneNumber.toString();
    return /^\d{10}$/.test(valueToTest);
  }

  static validateEmail(email: string): boolean {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }
}

export class ModUtils {
  static constrainNumToDigitLength(num: number, maxLength: number): number {
    if (num != null) {
      const numStr = num.toString();
      return numStr.length > maxLength
        ? parseInt(numStr.substring(0, maxLength), 10)
        : this.checkValid(num) ? num : undefined;
    }
  }
 static checkValid(num) :boolean {
    const pattern = /^(0|[1-9][0-9]*)$/;
    if (!pattern.test(num)) {
      return false
    }
    return true
  }
  static formatSeparatorWithCommas(valString: string) {
    return valString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static addition(num1:number, num2:number){
    return (num1 + num2);
  }
}
