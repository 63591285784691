import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { catchError,map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { SuaLetter, SuaLetterYear } from '../modules/sua-letter/filter/filter.component';
import { FirmModalComponent } from '../components/modals/firm-modal/firm-modal.component';
import { AlertService } from './alert.service';
@Injectable({
  providedIn: 'root'
})
export class SuaLetterService {
  id: any;
  number: number = 0.00;
  comission: number = 0.00;
  date: any;
  banks: any;
  isLoading: boolean = false;
  accountSelected: any = null;
  paymentDate: any;
  data:any
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    })
  };

  constructor(
    private http: HttpClient,
    private file: File,
    private fileOpener: FileOpener,
    public loadingCtrl: LoadingController,
    public platform: Platform,
    //private alertService:AlertService,
    private modalController:ModalController
   

  ) {

  }
  getSuaLetters(request: {
    sort: 'ASC' | 'DESC',
    from?: string,
    to?: string
  }): Observable<Array<SuaLetterYear>> {

   if(request.to=='Bimestre')
     request.to=''
    return this.http.post<{ data: Array<SuaLetterYear> }>(`${environment.API_URL}/sua_letters`, request)
      .pipe(
        map(({data}) => data),
      );
  }

  getSuaLetter(id: number): Observable<SuaLetter> {
    return this.http.get<{ data: SuaLetter }>(`${environment.API_URL}/sua_letters/${id}`).pipe(map(result => result.data));
  }
 

  createpdf(base64:any,name:string,id:any) {
    this.readSuaLetter(id);
    console.log('===>ITEM:',name);
    const writeDirectory = this.platform.is('ios') ? this.file.dataDirectory : this.file.externalDataDirectory;
    this.file.writeFile(writeDirectory, name, this.convertBase64ToBlob(base64, 'data:application/pdf;base64'), {replace: true})
      .then((value) => {
        console.log('==> VALUIE', value);
        this.fileOpener.open(`${writeDirectory}${name}`, 'application/pdf')
          .then(() => console.log('File is opened'))
          .catch(e => console.log('Error opening file', JSON.stringify(e)));
      })
      .catch(() => {
        console.error('Error writing pdf file');
      });
  }

  convertBase64ToBlob(b64Data, contentType): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  readSuaLetter(id: number): void {
    this.http.get(`${environment.API_URL}/sua_letters/edit/${id}`)
      .pipe(
        catchError((err) => {
          console.log('Ocurrio un error');
          return err;
        })).subscribe(_ => {
      console.log('Operación exitosa');
    });
  }

  // Creación del loading
  async presentLoading() {
    this.isLoading = true;
     return await this.loadingCtrl.create({
      message: 'Espere un momento',
    }).then(a => {
    a.present().then(() => {
      if (!this.isLoading) {
        a.dismiss();
      }
    })
   });
  }

  // Cierre del loading
  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss();
  }
  sign(file: any) {
    if (file.checkbox == 'activo'){
      this.id = file.high_employee_id;
    }else{
       this.id = file.id;
    }
    return this.http.put(`${environment.API_URL}/sua_letters/sign/${this.id}`,file);
  }
  async ShowSuaLetter(sua_letter:any) {
    this.data=sua_letter;
    if(this.data.signed == false){

      let modal = await this.modalController.create({
        component: FirmModalComponent,
        componentProps:{
          title: 'Por favor firma con tu dedo aquí la carta SUA.',
          subtitle:'¿Quieres aplicar la firma a todas tus cartas SUA?'},
      });
      modal.present();
     const { data } = await modal.onWillDismiss();
      if (data) {
       this.firm(data)
      }

    }else{
      this.openFile(this.data)
    }
  }
  firm(data) {
    this.presentLoading();
    let data_to_send={
      ...this.data,
      ...data,
    }
    let name=this.data.high_employee.name+' '+this.data.high_employee.paternal_last_name+" "+this.data.high_employee.mother_last_name
      this.sign({...data_to_send,name,email:this.data.high_employee.email})
        .subscribe((resp) => {
        this.dismissLoading();
         this.loading();
        },(err)=>console.log('err',err));

  }
  loading(){
    this.getSuaLetter(this.data.id).subscribe((res:any)=>{
      this.openFile(res)
    },(err)=>console.log('err get payslip',err))
  }
  openFile(data){
    if(this.platform.is('cordova'))
      this.createpdf(data.pdf_string,'Carta SUA',data.id)
    else{
      this.readSuaLetter(data.id);
      var contentType = "application/pdf";
      var dataBlob = this.convertBase64ToBlob(data.pdf_string, contentType);
      window.open(URL.createObjectURL(dataBlob),'_system');
    }
  }
  }
  
